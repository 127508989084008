import axios from 'axios';
import { IAddTxRequest, IOrderRequest } from './type';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const getProductPrice = () => {
  return instance.get('/api/start');
};

const sendOrder = (data: IOrderRequest) => {
  return instance.post('/api/order', data);
};

const addTx = (data: IAddTxRequest) => {
  return instance.post('/api/order/addtx', data);
};

const getOrders = (password: string, timestamp: number, unpaid: boolean) => {
  return instance.get(`/api/orders?from=${timestamp}&unpaid=${unpaid}`, {
    auth: {
      username: 'user',
      password: password,
    },
  });
};

export { getProductPrice, sendOrder, addTx, getOrders };
