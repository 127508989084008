import React from 'react';

export default function FAQ() {
  return (
    <div className='py-[70px] px-[37px] container mx-auto'>
      <h1 className='font-bold text-4xl md:text-[45px]'>FAQ</h1>
      <div className='mt-[13px] text-xl md:text-[25px] leading-[30px]'>
        <div className='mb-7'>
          <h3 className='font-bold'>Do you ship overseas?</h3>
          <p>
            We are a Hong Kong local business. Due to various food import
            restrictions for overseas, we can only deliver within Hong Kong
            currently.
          </p>
        </div>
        <div className='mb-7'>
          <h3 className='font-bold'>How long will it take to get my order?</h3>
          <p>
            Orders processed here usally take less than a week to arrive.
            Delivery details will be provided in your confirmation email.
          </p>
        </div>
        <div className='mb-7'>
          <h3 className='font-bold'>What shipping carriers do you use?</h3>
          <p>SF Express is our major carrier partner.</p>
        </div>
        <div>
          <h3 className='font-bold'>How much does the delivery cost?</h3>
          <p>
            Delivery is free of charge as the kittens are way too desperate to
            finding their way to your home.
          </p>
        </div>
      </div>
    </div>
  );
}
