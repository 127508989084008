export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const UPDATE_COST_SOL = "UPDATE_COST_SOL";

export const updateQuantity = (quantity: number) => ({
  type: UPDATE_QUANTITY,
  payload: quantity,
});

export const updateCostSol = (cost: number) => ({
  type: UPDATE_COST_SOL,
  payload: cost,
});
