import React, { useState, useEffect } from 'react';
import Button from '../../components/Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '../../components/TextField/TextField';
import { addTx, sendOrder } from '../../api/api';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import * as web3 from '@solana/web3.js';
import { useCallback } from 'react';
import { MemoProgram } from '../../memo';
import bs58 from 'bs58';
import { useStore } from '../../store';
import { updateQuantity } from '../../reducer/cartActions';
import { ICartState } from '../../reducer/cartReducer';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import MyDialog from '../../components/Dialog/Dialog';
import { IAddTxRequest } from '../../api/type';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';

let MEMO = 'SiNSAL5HsVzNB3j7v6umEP';

let sellerAcc = new web3.PublicKey(
  '8fRsJavPgRxS4KwQKmpXutSWZvZJqrVjup13miUdmm6T'
);

const convertSolToLamport = (sol: number) => {
  return sol * LAMPORTS_PER_SOL;
};

export default function Checkout() {
  const { connection } = useConnection();
  const { publicKey, sendTransaction, connected } = useWallet();

  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [tempData, setTempData] = useState({} as IAddTxRequest);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const { state, dispatch } = useStore();

  const { quantity, costSol } = state as ICartState;

  const { visible, setVisible } = useWalletModal();

  const handleConnect = useCallback(() => {
    setVisible(!visible);
  }, [setVisible, visible]);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      delivery: '',
      phoneNumber: '',
      currentQuantity: quantity,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phoneNumber: Yup.string()
        .max(10, 'Must be 10 characters')
        .required('Required'),
      delivery: Yup.string().required('Required'),
      currentQuantity: Yup.number()
        .positive('Must be more than 0')
        .required('Must be more than 0')
        .moreThan(0, 'Must be more than 0'),
    }),
    onSubmit: async (values, { resetForm }) => {
      let nonce = new Uint8Array(16);
      window.crypto.getRandomValues(nonce);
      MEMO = bs58.encode(nonce);

      if (!connected) handleConnect();
      else {
        const data = {
          count: values.currentQuantity,
          name: values.name,
          addr: values.delivery,
          phone: values.phoneNumber,
          email: values.email,
          memo: MEMO,
        };
        try {
          await sendOrder(data);
          resetForm();
          handleCheckout(data.count);
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const handleCheckout = async (quantity: number) => {
    const total = quantity * costSol;
    const lamports = convertSolToLamport(total);
    const signature = await handleMint(lamports);

    const data = {
      memo: MEMO,
      txid: signature,
    };
    setTempData(data);
    handleSendTx(data);
  };

  const handleSendTx = async (data: IAddTxRequest) => {
    try {
      await addTx(data);
      setShowLoading(false);
      dispatch(updateQuantity(0));
      navigate('/');
    } catch (error) {
      setShowLoading(false);
      setShowModal(true);
    }
  };

  const handleMint = useCallback(
    async (lamports: number) => {
      let newAcc = web3.Keypair.generate();

      let tx = new web3.Transaction().add(
        web3.SystemProgram.transfer({
          fromPubkey: publicKey as PublicKey,
          toPubkey: sellerAcc,
          lamports: lamports,
        }),
        MemoProgram.buildMemo({
          memo: MEMO,
          signer_public_keys: [publicKey as PublicKey],
        })
      );
      setShowLoading(true);
      let signature = await sendTransaction(tx, connection);
      await connection.confirmTransaction(signature, 'finalized');
      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  const onResend = () => {
    setShowModal(false);
    setShowLoading(true);
    handleSendTx(tempData);
  };

  return (
    <div className='w-full flex flex-col sm:flex-row-reverse justify-center px-3 py-5 container mx-auto gap-8 relative'>
      {quantity ? (
        <>
          <div className='min-w-[230px] px-3'>
            <h3 className='font-bold text-xl mb-4'>Order Summary</h3>
            <p className='mb-2 flex items-center justify-between'>
              Amount
              <div className='flex flex-row h-9 w-24 relative bg-transparent'>
                <button
                  className='h-full w-20 rounded-l-full cursor-pointer outline-none border-2 border-[#AFAFAF] border-r-0'
                  type='button'
                  onClick={() => {
                    if (+formik.values.currentQuantity) {
                      formik.setFieldValue(
                        'currentQuantity',
                        +formik.values.currentQuantity - 1
                      );
                    }
                  }}
                >
                  <span className='m-auto text-[20px] leading-none text-[#AFAFAF] font-medium'>
                    −
                  </span>
                </button>
                <input
                  name='currentQuantity'
                  type='number'
                  className='text-center w-[33px] flex items-center border-2 border-[#AFAFAF] border-x-0 focus:ring-0 focus:border-[#AFAFAF] px-0 bg-transparent'
                  onChange={formik.handleChange}
                  value={formik.values.currentQuantity}
                  placeholder='0'
                />
                <button
                  className='h-full w-20 rounded-r-full cursor-pointer border-2 border-[#AFAFAF] border-l-0'
                  type='button'
                  onClick={() =>
                    formik.setFieldValue(
                      'currentQuantity',
                      +formik.values.currentQuantity + 1
                    )
                  }
                >
                  <span className='m-auto text-[20px] leading-none text-[#AFAFAF] font-medium'>
                    +
                  </span>
                </button>
              </div>
            </p>
            {formik.touched.currentQuantity &&
              formik.errors.currentQuantity && (
                <p className='text-[14px] text-red-600'>
                  {formik.errors.currentQuantity}
                </p>
              )}
            <div className='flex items-center justify-end my-2 mr-[44px]'>
              <p>x</p>
            </div>
            <div className='flex items-center justify-between my-2 mt-3 mr-7'>
              <p>SOL</p>
              <p>{costSol}</p>
            </div>
            <hr />
            <div className='flex items-center justify-between mt-3 mr-7'>
              <p>TOTAL</p>
              <p>{(+formik.values.currentQuantity * costSol).toFixed(3)}</p>
            </div>
          </div>
          <form
            className='w-full px-3 max-w-[450px]'
            onSubmit={formik.handleSubmit}
          >
            <h3 className='font-bold text-xl mb-2'>Delivery Address</h3>
            <div className='mb-2'>
              <label className='text-[14px]' htmlFor='fullName'>
                Full Name
              </label>
              <TextField
                id='fullName'
                placeholder='Full Name'
                type='text'
                name='name'
                onChange={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <p className='text-[14px] text-red-600 m-0'>
                  {formik.errors.name}
                </p>
              )}
            </div>
            <div className='mb-2'>
              <label className='text-[14px]' htmlFor='phoneNumber'>
                Phone Number
              </label>
              <TextField
                id='phoneNumber'
                placeholder='Phone Number'
                type='text'
                name='phoneNumber'
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <p className='text-[14px] text-red-600'>
                  {formik.errors.phoneNumber}
                </p>
              )}
            </div>
            <div className='mb-2'>
              <label className='text-[14px]' htmlFor='email'>
                Email Address
              </label>
              <TextField
                id='email'
                type='email'
                placeholder='example@gmail.com'
                name='email'
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <p className='text-[14px] text-red-600'>
                  {formik.errors.email}
                </p>
              )}
            </div>
            <div className='mb-2'>
              <label className='text-[14px]' htmlFor='delivery'>
                Delivery Address (Hong Kong Only)
              </label>
              <TextField
                id='delivery'
                placeholder='Delivery Address'
                name='delivery'
                onChange={formik.handleChange}
                value={formik.values.delivery}
                onBlur={formik.handleBlur}
                type='textarea'
                rows={3}
              />
              {formik.touched.delivery && formik.errors.delivery && (
                <p className='text-[14px] text-red-600'>
                  {formik.errors.delivery}
                </p>
              )}
            </div>
            <Button className='mt-5' type='submit'>
              {connected ? 'Checkout' : 'Connect wallet'}
            </Button>
          </form>
        </>
      ) : (
        <p>There is no item to checkout</p>
      )}
      <Loading show={showLoading} />
      <MyDialog
        show={showModal}
        title='An unexpected error occurred. Do you want to resend ?'
        onClose={() => setShowModal(false)}
        onOk={onResend}
      />
    </div>
  );
}
