import React from "react";
import { ICartState } from "../reducer/cartReducer";

interface IContextProps {
  state: unknown | ICartState;
  dispatch: ({ type }: { type: string }) => void;
}

const Store = React.createContext({} as IContextProps);

export const useStore = () => React.useContext(Store);

export const StoreProvider = ({ children, initialState, reducer }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <Store.Provider value={value}>{children}</Store.Provider>;
};
