import React from 'react';
import Icon from '../Icon/Icon';

interface ILoadingProps {
  show: boolean;
  title: string;
  onClose: () => void;
  onOk: () => void;
}

export default function MyDialog({
  show,
  onClose,
  title,
  onOk,
}: ILoadingProps) {
  return (
    <div
      id='defaultModal'
      aria-hidden='true'
      className={`${
        show ? 'flex' : 'hidden'
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full justify-center items-center bg-slate-100 bg-opacity-30`}
      role='dialog'
    >
      <div className='relative p-4 w-full max-w-md h-full md:h-auto'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <button
            type='button'
            className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
            data-modal-toggle='popup-modal'
            onClick={onClose}
          >
            <Icon name='close' className='w-5 h-5' />
          </button>
          <div className='p-6 text-center'>
            <Icon
              name='warning'
              className='mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200'
            />
            <h3 className='mb-5 text-lg text-black font-medium'>{title}</h3>
            <button
              data-modal-toggle='popup-modal'
              type='button'
              className='text-white focus:ring-4 focus:outline-none bg-indigo-600 hover:bg-indigo-700 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2'
              onClick={onOk}
            >
              Yes, I do
            </button>
            <button
              data-modal-toggle='popup-modal'
              type='button'
              className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10'
              onClick={onClose}
            >
              No, cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
