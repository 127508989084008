import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStore } from '../../store';
import { getProductPrice } from '../../api/api';
import { updateCostSol, updateQuantity } from '../../reducer/cartActions';
import { ICartState } from '../../reducer/cartReducer';
import product1 from '../../assets/product1.png';
import product2 from '../../assets/product2.png';
import cat from '../../assets/cat.png';
import GrennLogo from '../../assets/GrennLogo.png';
import GrennXLogo from '../../assets/GrennXLogo.png';
import LuckyKittenLogo from '../../assets/LuckyKittenLogo.png';
import ProductLuckyKitten from '../../assets/ProductLuckyKitten.png';
import logoBlack from '../../assets/logo_black.png';
import group from '../../assets/group.png';
import line from '../../assets/line.png';
import product2Big from '../../assets/product2-big.jpg';
import Slider from 'react-slick';
import axios from 'axios';

const financial = (number: number) => {
  return number.toFixed(4);
};

const convertSolToUSD = (sol: number, price: number) => {
  return (sol * price).toFixed(2);
};

const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 800,
  autoplaySpeed: 3000,
  arrows: false,
};

export default function Home() {
  const { state, dispatch } = useStore();

  const { quantity, costSol } = state as ICartState;
  const [slide, setSlide] = useState<Slider | null>();

  const [solToUSD, setSolToUSD] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrice = async () => {
      const { data } = await axios.get(
        'https://api.coingecko.com/api/v3/coins/markets',
        { params: { vs_currency: 'usd', ids: 'solana' } }
      );
      setSolToUSD(data[0].current_price);
    };

    fetchPrice();

    getProductPrice().then((response) => {
      dispatch(updateCostSol(response.data.cost_sol));
    });
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      price: 1,
    },
    validationSchema: Yup.object({
      price: Yup.number()
        .positive('Must be more than 0')
        .required('Must be more than 0')
        .moreThan(0, 'Must be more than 0'),
    }),
    onSubmit: (values) => {
      dispatch(updateQuantity(quantity + formik.values.price));
      formik.resetForm();
      navigate('checkout');
    },
  });

  const onIncreaseAmount = () => {
    formik.setFieldValue('price', formik.values.price + 1);
  };

  const onDecreaseAmount = () => {
    if (formik.values.price > 1) {
      formik.setFieldValue('price', formik.values.price - 1);
    }
  };

  return (
    <div className='flex flex-col justify-center items-center pt-10 md:pt-28'>
      <div className='grid grid-cols-1 md:grid-cols-2 items-end container mx-auto px-7'>
        <div className='py-10'>
          <img src={GrennXLogo} alt='Grenn logo' />
          <img src={LuckyKittenLogo} alt='LuckyKitten Logo' />
        </div>
        <div className='flex justify-end'>
          <img
            src={ProductLuckyKitten}
            alt='ProductLuckyKitten'
            className='w-full'
          />
        </div>
      </div>
      <div className='container mx-auto px-7'>
        <p className='text-xl text-center'>
          GRENN from Hong Kong and Lucky Kittens from the Solana Blockchain
          joined forces together with the help of CG, and created a
          mouth-watering collection of snacks that no kittens nor human can
          resist. For the cat lovers and collectors that are crypto rich (and
          cash poor), we accept payment in your favourite token, $SOL and $USDC.
        </p>
      </div>
      <div className='mt-[45px] container mx-auto px-7 flex justify-center'>
        <a
          href='#shop-now'
          className='uppercase text-[25px] rounded-[30px] border-2 border-white py-[10px] px-[60px] hover:bg-white hover:text-black transition-all duration-1000'
        >
          Shop now
        </a>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 py-10 items-center mt-10 md:mt-20 container mx-auto px-7'>
        <div className='flex flex-col justify-center'>
          <img src={GrennLogo} alt='Grenn logo' className='w-1/2' />
          <p className='text-[20px] lg:w-2/3 p-5'>
            We are GRENN.
            <br />
            We don’t just like feeling good.
            <br />
            We like doing good too, with stuff like this: #DELICIOUS #SHARING
            #HAPPINESS #CRAFTMENSHIP #PLAYFUL #MOMENTS & MORE.
          </p>
        </div>
        <div className='w-full flex justify-end items-end'>
          <div className='relative w-full md:w-[484px] md:h-[332px] mt-10 md:mt-0'>
            <img
              src={line}
              alt='line'
              className='hidden md:block absolute top-0 bottom-0 md:left-[-20px] lg:left-[-160px] xl:left-[-300px] m-auto'
            />
            <img
              src={group}
              alt='group'
              className='md:absolute top-0 bottom-0 left-0 right-0 m-auto'
            />
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 items-end py-10 mt-10 md:mt-[-280px] lg:mt-[-80px] container mx-auto px-7'>
        <div className='order-1 md:order-none'>
          <div className='relative w-full md:w-[681px] md:h-[610px]'>
            <img
              src={line}
              alt='line'
              className='scale-[-1] hidden md:block absolute bottom-14 md:right-[318px] lg:right-[210px] xl:right-[90px]'
            />
            <img
              src={cat}
              alt='cat'
              className='md:absolute top-0 bottom-0 left-0 right-0 md:w-[55%] lg:w-[80%] mt-auto'
            />
          </div>
        </div>
        <div className='flex flex-col justify-center'>
          <img src={LuckyKittenLogo} alt='LuckyKitten Logo' />
          <p className='md:w-2/3 p-5 text-[20px]'>
            Lucky Kittens is an exclusive NFT community, inspired by the
            maneki-nekos ubiquitous around Asia. There is a collection of 5,888
          </p>
        </div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className='bg-white w-full text-black flex flex-col lg:flex-row py-10 md:py-[83px] px-6 mt-[100px] gap-14'
        id='shop-now'
      >
        <div className='flex items-end gap-6 lg:w-[65%] flex-col xl:flex-row'>
          <div className='flex flex-row xl:flex-col w-full xl:w-fit gap-[10px] md:justify-around mb-[-100px] exsm:mb-0 sm:mb-5 md:mb-10 lg:mb-0 justify-between sm:gap-5'>
            <img
              src={product1}
              alt='product1'
              className='cursor-pointer w-[47%] md:max-w-[350px] lg:max-w-[250px] xl:w-full'
              onClick={() => (slide as Slider).slickGoTo(0)}
            />
            <img
              src={product2}
              alt='product2'
              className='cursor-pointer w-[47%] md:max-w-[350px] lg:max-w-[250px] xl:w-full'
              onClick={() => (slide as Slider).slickGoTo(1)}
            />
          </div>
          <div className='w-full xl:w-fit flex justify-center grow h-full relative min-h-[350px]'>
            <Slider
              {...settings}
              className='absolute w-full h-full'
              ref={(sliderRef) => setSlide(sliderRef)}
            >
              <div className='box-image justify-center'>
                <img
                  src={ProductLuckyKitten}
                  alt='ProductLuckyKitten'
                  className='transition-all duration-1000 lg:max-w-[470px]'
                />
              </div>
              <div className='box-image justify-center'>
                <img
                  src={product2Big}
                  alt='ProductLuckyKitten'
                  className='transition-all duration-1000 max-h-[350px] lg:max-w-[470px]'
                />
              </div>
            </Slider>
          </div>
        </div>
        <div className='flex flex-col grow justify-center'>
          <div>
            <img src={logoBlack} alt='logoBlack' />
          </div>
          <div className='mt-[27px] font-medium text-[25px]'>
            <h3 className='leading-[30px]'>
              Limited Edition | GRENN x LUCKY KITTENS Snack Box
            </h3>
            <div className='mt-4 flex items-center gap-9'>
              <p>
                {financial(costSol)} SOL (~ {convertSolToUSD(costSol, solToUSD)} USD)
              </p>
              <p className='text-[#2A8A28]'>In Stock</p>
            </div>
          </div>
          <div className='flex items-center mt-[35px] gap-5 flex-col sm:flex-row'>
            <div className='flex flex-row h-[58px] w-32 relative bg-transparent'>
              <button
                className='h-full w-20 rounded-l-full cursor-pointer outline-none border-2 border-[#AFAFAF] border-r-0'
                type='button'
                onClick={onDecreaseAmount}
              >
                <span className='m-auto text-2xl leading-none text-[#AFAFAF] font-medium'>
                  −
                </span>
              </button>
              <input
                id='price'
                name='price'
                type='number'
                className='text-center w-[40px] text-[30px] flex items-center border-2 border-[#AFAFAF] border-x-0 focus:ring-0 focus:border-[#AFAFAF] px-0'
                onChange={formik.handleChange}
                value={formik.values.price}
                placeholder='0'
              />
              <button
                className='h-full w-20 rounded-r-full cursor-pointer border-2 border-[#AFAFAF] border-l-0'
                type='button'
                onClick={onIncreaseAmount}
              >
                <span className='m-auto text-2xl leading-none text-[#AFAFAF] font-medium'>
                  +
                </span>
              </button>
            </div>
            <button
              type='submit'
              className='font-medium text-[25px] text-white py-[14px] px-[45px] bg-[#2A8A28] rounded-[30px] leading-[30px] transition-all duration-500 hover:bg-[#10ae0d]'
            >
              Add to Cart
            </button>
          </div>
          {formik.touched.price && formik.errors.price ? (
            <p className='text-[14px] text-red-600 m-0'>
              {formik.errors.price}
            </p>
          ) : null}
        </div>
      </form>
    </div>
  );
}
