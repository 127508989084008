import { UPDATE_QUANTITY, UPDATE_COST_SOL } from "./cartActions";

export interface ICartState {
  quantity: number;
  costSol: number;
}

interface IActions {
  type: string;
  payload: any;
}

export const initialState: ICartState = {
  quantity: 0,
  costSol: 0.239,
};

export const cartReducer = (state = initialState, action: IActions) => {
  switch (action.type) {
    case UPDATE_QUANTITY:
      return {
        ...state,
        quantity: action.payload,
      };
    case UPDATE_COST_SOL:
      return {
        ...state,
        costSol: action.payload,
      };
    default:
      break;
  }
};
