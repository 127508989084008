import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "../Button/Button";

const WalletModalButton = () => {
  const { visible, setVisible } = useWalletModal();

  const handleClick = useCallback(() => {
    setVisible(!visible);
  }, [setVisible, visible]);

  return (
    <Button onClick={handleClick} className="text-[0.8rem] sm:text-[1rem]">
      CONNECT WALLET
    </Button>
  );
};

const WalletConnectButton = () => {
  const { wallet, connect, connecting, connected } = useWallet();

  const handleClick = useCallback(() => {
    connect().catch((error) => console.log(error));
  }, [connect]);

  const content = useMemo(() => {
    if (connecting) {
      return "CONNECTING ...";
    }
    if (connected) {
      return "CONNECTED";
    }
    if (wallet) {
      return "CONNECT WALLET";
    }
    return "CONNECT WALLET";
  }, [connected, connecting, wallet]);

  return (
    <Button onClick={handleClick} className="text-[0.8rem] sm:text-[1rem]">
      {content}
    </Button>
  );
};

const WalletMultiButton = (props: any) => {
  const { publicKey, wallet, disconnect, connect } = useWallet();
  const { setVisible } = useWalletModal();
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLUListElement>(null);

  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey]);
  const walletAddress = useMemo(() => {
    if (!wallet || !base58) return null;
    return base58.slice(0, 4) + "..." + base58.slice(-4);
  }, [wallet, base58]);

  const openDropdown = useCallback(() => {
    setActive(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setActive(false);
  }, []);

  const openModal = useCallback(() => {
    setVisible(true);
    closeDropdown();
  }, [closeDropdown, setVisible]);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const node = ref.current;

      // Do nothing if clicking dropdown or its descendants
      if (!node || node.contains(event.target as Node)) return;

      closeDropdown();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, closeDropdown]);

  useEffect(() => {
    if (wallet?.readyState === "Installed" && !base58) {
      connect().catch((error) => console.error(error));
    }
  }, [wallet, base58, connect]);

  if (!wallet) {
    return <WalletModalButton />;
  }
  if (!walletAddress) return <WalletConnectButton />;

  return (
    <div className="wallet-adapter-dropdown">
      <Button
        aria-expanded={active}
        onClick={openDropdown}
        className="text-[0.8rem] sm:text-[1rem]"
        {...props}
      >
        {walletAddress}
      </Button>
      <ul
        aria-label="dropdown-list"
        className={`wallet-adapter-dropdown-list ${
          active && "wallet-adapter-dropdown-list-active"
        }`}
        ref={ref}
        role="menu"
      >
        <li
          onClick={openModal}
          className="wallet-adapter-dropdown-list-item"
          role="menuitem"
        >
          Change wallet
        </li>
        <li
          onClick={disconnect}
          className="wallet-adapter-dropdown-list-item"
          role="menuitem"
        >
          Disconnect
        </li>
      </ul>
    </div>
  );
};

export default WalletMultiButton;
