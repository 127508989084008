import { ReactComponent as facebook } from '../../assets/facebook.svg';
import { ReactComponent as instagram } from '../../assets/instagram.svg';
import { ReactComponent as twitter } from '../../assets/twitter.svg';
import { ReactComponent as warning } from '../../assets/warning.svg';
import { ReactComponent as close } from '../../assets/close.svg';

export const IconMap = {
  facebook,
  instagram,
  twitter,
  warning,
  close,
};
