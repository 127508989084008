import React, { FC } from "react";

enum ETextField {
  TEXT = "text",
  TEXTAREA = "textarea",
}

type InputProps = {
  className?: string;
} & (
  | {
      type?: ETextField.TEXT;
      onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    }
  | {
      type: ETextField.TEXTAREA;
      onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    }
);

const Input: FC<InputProps> = (props: InputProps) => {
  if (props.type === ETextField.TEXTAREA) {
    return <textarea {...props} />;
  }
  return <input {...props}/>;
};

export default function TextField({ ...rest }) {
  return (
    <Input
      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-black"
      {...rest}
    />
  );
}
