import React from 'react';
import Icon from '../Icon/Icon';
import GrennLogo from '../../assets/GrennLogo.png';
import LuckyKittenLogo from '../../assets/LuckyKittenLogo.png';
import CGLogo from '../../assets/CGLogo.png';

export default function Footer() {
  return (
    <div className='py-4 text-center mt-[67px] container mx-auto'>
      <div className='flex flex-wrap mb-3 justify-center items-center gap-3'>
        <a href='https://www.cgentium.com/' target='_blank' rel='noreferrer'>
          <img src={CGLogo} alt='CG logo' className='h-7' />
        </a>{' '}
        <a href='https://www.grenn.com.hk/' target='_blank' rel='noreferrer'>
          <img src={GrennLogo} alt='GRENN logo' className='h-6' />
        </a>
        <a
          href='https://www.facebook.com/grenn.official'
          target='_blank'
          rel='noreferrer'
        >
          <Icon name='facebook' width={45} height={45} />
        </a>
        <a
          href='https://www.instagram.com/grenn_official'
          target='_blank'
          rel='noreferrer'
        >
          <Icon name='instagram' width={45} height={45} />
        </a>
        <a href='https://luckykittens.io/' target='_blank' rel='noreferrer'>
          <img src={LuckyKittenLogo} alt='Lucky Kittens logo' className='h-6' />
        </a>
        <a
          href='https://twitter.com/luckykittensnft'
          target='_blank'
          rel='noreferrer'
        >
          <Icon name='twitter' width={45} height={45} />
        </a>
      </div>
      <div>
        Powered by{' '}
        <a
          href='https://www.cgentium.com/'
          target='_blank'
          className='font-bold'
          rel='noreferrer'
        >
          CGentium
        </a>
      </div>
    </div>
  );
}
