import React, { useEffect, useState } from 'react';
import { getOrders } from '../../api/api';
import TextField from '../../components/TextField/TextField';
import moment from 'moment';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';

enum ETimeStamp {
  WEEK,
  MONTH,
  QUARTER,
  YEAR,
}

const getTimestamp = (time: number) => {
  switch (time) {
    case ETimeStamp.WEEK:
      return 604800000;
    case ETimeStamp.MONTH:
      return 2629800000;
    case ETimeStamp.QUARTER:
      return 7884000000;
    case ETimeStamp.YEAR:
      return 31556952000;
    default:
      return 604800000;
  }
};

export default function Order() {
  const [listOrder, setListOrder] = useState<IOrder[]>([]);
  const [password, setPassword] = useState(
    sessionStorage.getItem('password') as string
  );
  const [showList, setShowList] = useState(false);
  const [filter, setFilter] = useState(ETimeStamp.WEEK);
  const [error, setError] = useState('');
  const [showUnpaid, setShowUnpaid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (password) onGetOrders();
  }, [filter, showUnpaid]);

  const onGetOrders = () => {
    setLoading(true);
    getOrders(password, getTimestamp(filter), showUnpaid)
      .then((response) => {
        setListOrder(response.data);
        setLoading(false);
        setShowList(true);
        setError('');
        sessionStorage.setItem('password', password);
      })
      .catch(() => {
        setLoading(false);
        setError('Wrong password');
      });
  };

  const onClickTxid = (txid: string) => {
    window.open(`https://explorer.solana.com/tx/${txid}`);
  };

  const onSubmit = () => {
    if (!password) {
      setError('Required');
    } else onGetOrders();
  };

  return (
    <div className='min-h-[70vh] flex justify-center px-3 py-5 container mx-auto'>
      {!showList ? (
        <div className='w-full sm:max-w-[300px]'>
          <p className='text-[16px] mb-1'>Please fill password</p>
          <TextField
            placeholder='Password'
            type='text'
            name='password'
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value);
            }}
          />
          {error && <p className='text-[14px] text-red-600 mt-1'>{error}</p>}
          <Button className='mt-4' onClick={onSubmit}>
            Submit
          </Button>
        </div>
      ) : (
        <div className='w-full'>
          <div className='flex justify-between sm:items-center mb-4'>
            <h1 className='text-xl font-semibold'>Transactions</h1>
            <div className='flex sm:items-center flex-col-reverse sm:flex-row gap-3'>
              <div className='form-check flex'>
                <input
                  className='form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-indigo-600 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
                  type='checkbox'
                  checked={showUnpaid}
                  onChange={() => setShowUnpaid(!showUnpaid)}
                />
                <label className='form-check-label inline-block text-white whitespace-nowrap'>
                  Unpaid orders
                </label>
              </div>
              <select
                onChange={(e) => {
                  setFilter(+e.target.value);
                }}
                value={filter}
                className='bg-gray-50 border-0 text-gray-900 text-sm rounded-lg block w-auto min-w-[100px] max-w-[150px] p-2.5 focus:border-0 focus:ring-0 font-medium'
              >
                <option value={ETimeStamp.WEEK}>Week</option>
                <option value={ETimeStamp.MONTH}>Month</option>
                <option value={ETimeStamp.QUARTER}>Quarter</option>
                <option value={ETimeStamp.YEAR}>Year</option>
              </select>
            </div>
          </div>
          <div className='relative overflow-x-auto shadow-md rounded-lg h-fit w-full'>
            <table className='w-full text-sm text-left text-white'>
              <thead className='text-xs uppercase bg-[#141816]'>
                <tr>
                  <th scope='col' className='px-6 py-3'>
                    Count
                  </th>
                  <th scope='col' className='px-6 py-3'>
                    Name
                  </th>
                  <th scope='col' className='px-6 py-3'>
                    Email
                  </th>
                  <th scope='col' className='px-6 py-3 min-w-[200px]'>
                    Address
                  </th>
                  <th scope='col' className='px-6 py-3'>
                    Phone
                  </th>
                  <th scope='col' className='px-6 py-3'>
                    Txid
                  </th>
                  <th scope='col' className='px-6 py-3'>
                    Timestamp
                  </th>
                </tr>
              </thead>
              <tbody>
                {listOrder.map((item, index) => (
                  <tr key={index} className='bg-[#1e2423]'>
                    <td className='px-6 py-4'>{item.count}</td>
                    <td className='px-6 py-4'>{item.name}</td>
                    <td className='px-6 py-4'>{item.email}</td>
                    <td className='px-6 py-4'>{item.addr}</td>
                    <td className='px-6 py-4'>{item.phone}</td>
                    <td className='px-6 py-4'>
                      <p
                        className='break-all w-[300px] text-[#33a382] hover:text-[#216954] cursor-pointer'
                        onClick={() => onClickTxid(item.txid)}
                      >
                        {item.txid}
                      </p>
                    </td>
                    <td className='px-6 py-4'>
                      {moment(+item.timestamp / 1000).format('ll')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <Loading show={loading} />
    </div>
  );
}
