import React from "react";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  onClick?: () => void;
  children: string;
}

export default function Button({
  className,
  children,
  onClick,
  ...rest
}: IButtonProps) {
  return (
    <button
      className={`w-full whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}
