import { Link } from "react-router-dom";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import WalletMultiButton from "../WalletMultiButton/WalletMultiButton";
import { useStore } from "../../store";
import { ICartState } from "../../reducer/cartReducer";
import TopLogo from "../../assets/TopLogo.png";
import { ClipboardListIcon } from "@heroicons/react/outline";

export default function Header() {
  const { state } = useStore();

  const { quantity } = state as ICartState;
  return (
    <div className='px-4 container mx-auto'>
      <div className='flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10'>
        <div className='flex justify-start lg:w-0 lg:flex-1'>
          <Link to='/'>
            <img
              className='h-[1.2rem] sm:h-6'
              src={TopLogo}
              alt='GRENN x Lucky Kittens'
            />
          </Link>
        </div>

        <div className='flex items-center justify-end md:flex-1 lg:w-0'>
          <Link to='/checkout' className='relative'>
            <ShoppingCartIcon className='h-7 w-7 text-indigo-600 cursor-pointer' />
            {!!quantity && (
              <div className='w-5 h-5 rounded-full absolute bg-red-600 text-white text-[14px] font-semibold flex items-center justify-center top-[-8px] right-[-8px]'>
                {quantity}
              </div>
            )}
          </Link>
          <Link to='order'>
            <ClipboardListIcon className='ml-1 sm:ml-3 h-7 w-7 text-indigo-600' />
          </Link>
          <div className='ml-3 sm:ml-5 flex items-center'>
            <WalletMultiButton />
          </div>
        </div>
      </div>
    </div>
  );
}
